import React, { createContext, useEffect, useState, useRef } from 'react';
import { ApiService } from '../Services/apiservices';
import sessionCartData from './cart_session_data';
const DataContext = createContext();
export const DataProvider = ({ children }) => {
  const [toggleCartModal , setToggleCartModal] = useState(false)
  const [toggleSignRukmanModal , setToggleSignRukmanModal] = useState(false)
  const [toggleSearchModal , setToggleSearchModal] = useState(false)
  const [toggleMenuModal , setToggleMenuModal] = useState(false)
  const [toggleAddressModal , setToggleAddressModal] = useState(false)
  const [toggleLoginModal , setToggleLoginModal] = useState(false)
  const [userToken, setUserToken] = useState(localStorage.getItem("USER_TOKEN"));
  const [settingsData , setSettingData] = useState({})
  const [settingsImageBaseUrl , setSettingImageBaseUrl] = useState('')
  const [cartSessionData, setCartSessionData] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [spinnerCubLoader, setSpinnerCubLoader] = useState(0);
  const [couponSession, setCouponSession] = useState({ discount_amount: 0.00, promo_id: 0, promo_code: "", cart_amount: 0.00 });
  const [cartSummary, setCartSummary] = useState({});
  const [rowUserData, setRowUserData] = useState({});
  const [userAddressList, setUserAddressList] = useState([]);
  const [addressSession, setAddressSession] = useState({});
  const [favCount, setFavCount] = useState(0);
  const [userAddressDetails, setUserAddressDetails] = useState({
    ua_id: 0,
    ua_fname: "",
    ua_lname: "",
    ua_email: "",
    ua_mobile: "",
    ua_pincode: "",
    ua_state_name: "",
    ua_city_name: "",
    ua_country_name: "",
    ua_state_id: 0,
    ua_city_id: 0,
    ua_apartment: "",
    ua_default_address: "",
    ua_complete_address: '',
    ua_country_id: '',
    ua_address_type: "",
    ua_address_type_other: "",
    ua_user_id: 0,
  });
  const didMountRef = useRef(true);
  const dataArray = sessionCartData();




  useEffect(() => {
    if (didMountRef.current) {
      getSettingData()
      
      if (localStorage.getItem("USER_TOKEN")) {
        getCartSessionData()
        // getFavCount()
      } else {
        setCartSessionData(dataArray[1])
        setCartCount(dataArray[1].length)
        setCartSummary(dataArray[3])
      }
    }
    didMountRef.current = false;
  }, [])

  const getCartSessionData = () => {
    const dataString = {
      coupon_session: localStorage.getItem("COUPON_SESSION"),
    };
    ApiService.postData("cartSessionData", dataString).then((res) => {
      if (res.data.status === "success") {
        setCartSessionData(res.data.resCartData)
        setCartCount(res.data.resCartData.length)
        setCartSummary(res.data.cartSummary)
      } else {
        setCartSessionData([])
        setCartCount(0)
        setCartSummary({})
      }
    });
  }

  const getSettingData = () => {
    try {
      ApiService.fetchData('/settingsdata').then((res) => {
        if (res.status === 'success') {
          setSettingData(res.sitesettings)
          setSettingImageBaseUrl(res.setting_image_path)
        }
      })
    } catch (error) {
      console.error('An error occurred while fetching blog data:', error);
    }
  }


 
  return (
    <DataContext.Provider value={
      {
        toggleCartModal, setToggleCartModal,
        toggleLoginModal, setToggleLoginModal,
        settingsData, setSettingData,
        settingsImageBaseUrl, setSettingImageBaseUrl,
        toggleMenuModal, setToggleMenuModal,
       userToken, setUserToken,
       toggleSearchModal,setToggleSearchModal,
       toggleSignRukmanModal,setToggleSignRukmanModal,
       spinnerCubLoader,setSpinnerCubLoader,
       cartSessionData,setCartSessionData,
       cartSummary,setCartSummary,
       couponSession,setCouponSession,
       cartCount,setCartCount,
       rowUserData,setRowUserData,
       favCount,setFavCount,
       userAddressDetails,setUserAddressDetails,
       userAddressList,setUserAddressList,
       toggleAddressModal,setToggleAddressModal
      }
    }>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;