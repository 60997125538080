import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../../Components/Services/apiservices";
import constant from "../../../Components/Services/constant";
const HomeBlog=()=>{
  const navigate = useNavigate()
  const didMountRef = useRef(true);
  const [blogsData, setBlogsData] = useState([]);
  const [defaultImage, setDefaultImage] = useState('');

  useEffect(() => {
      if (didMountRef.current) {
          ApiService.fetchData("featuredBlogsList").then((res) => {
              if (res.status === "success") {
                  setBlogsData(res.blogsData);
                  setDefaultImage(res.blog_image_path)
              }
          });
      }
      didMountRef.current = false;
  }, []);
    return(<>   
   {blogsData && blogsData.length>0?
        <div className="row">
          {blogsData[0] && <div className="col-lg-4">
            <article className="blog-list">
              <figure className="order1 blog-list-media mb-3">
              <img src={blogsData[0]?.blog_image ? defaultImage + blogsData[0]?.blog_image : constant.DEFAULT_IMAGE} alt={blogsData[0]?.blog_name}></img>
              </figure>
             <div className="blog-list-content">
              <h2 className="title">{blogsData[0]?.blog_name}</h2>
              <p>{blogsData[0]?.blog_short_description}</p>
              <a href={"/blog/"+blogsData[0]?.blog_slug} className="linebutton linebutton-small">
                  Read article
                </a>
             </div>
            </article>
          </div>}
          
          {blogsData[1] &&  <div className="col-lg-4">
            <article className="blog-list">
              <figure className="order1 blog-list-media mb-3">
              <img src={blogsData[1]?.blog_image ? defaultImage + blogsData[1]?.blog_image : constant.DEFAULT_IMAGE} alt={blogsData[1]?.blog_name}></img>
              </figure>
             <div className="blog-list-content">
              <h2 className="title">{blogsData[1]?.blog_name}</h2>
              <p>{blogsData[1]?.blog_short_description}</p>
              <a href={"/blog/"+blogsData[1]?.blog_slug} className="linebutton linebutton-small">
                  Read article
                </a>
             </div>
            </article>
          </div>}
         
          {blogsData[2] &&  <div className="col-lg-4">
            <article className="blog-list">
              <figure className="blog-list-media order-2">
              <img src={blogsData[2]?.blog_image ? defaultImage + blogsData[2]?.blog_image : constant.DEFAULT_IMAGE} alt={blogsData[2]?.blog_name}></img>
              </figure>
             <div className="blog-list-content order-1 mb-3">
              <h2 className="title">{blogsData[2]?.blog_name}</h2>
              <p>{blogsData[2]?.blog_short_description}</p>
              <a href={"/blog/"+blogsData[2]?.blog_slug} className="linebutton linebutton-small">
                  Read article
                </a>
             </div>
            </article>
          </div>}
         
        </div>
      :null}
    
    </>)
}
export default HomeBlog